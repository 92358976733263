import React, { useState } from 'react';
import {MainLayout, InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import portfolios from '../data/portfolios';
import Menu from '../Components/Menu';
import Button from '../Components/Button';

const allButtons = ['All', ...new Set(portfolios.map(item => item.category))];  //A Set data structure does not repeat data regardless of how many similar fields appear in between its brackets

function PortfoliosPage() {
    const [menuItem, setMenuItems] = useState(portfolios);
    // eslint-disable-next-line
    const [button, setButtons] = useState(allButtons);

    const filter = (button) => {

        if (button === 'All') {
            setMenuItems(portfolios);
            return;
        }

        const filteredData = portfolios.filter(item => item.category === button);
        setMenuItems(filteredData);
    }

    return (
        <MainLayout>
            <Title title={'Portfolios'} span={'Portfolios'} />
            <InnerLayout>

                <Button filter={filter} button={button}/>
                <Menu menuItem={menuItem}/>
            </InnerLayout>
        </MainLayout>
    )
}

export default PortfoliosPage;
