import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../Components/PrimaryButton';
import Title from '../Components/Title';
import {MainLayout, InnerLayout} from '../styles/Layouts';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactItem from '../Components/ContactItem';


function ContactPage() {

    const phone = <PhoneIcon />
    const email = <EmailIcon />
    const location = <LocationOnIcon />

    return (
        <MainLayout>
            <Title title={'Contact'} span={'Contact'}/>
            <ContactPageStyled>
                <InnerLayout className={'contact-section'}>
                    <div className="left-content">
                        <div className="contact-title">
                            <h4>Get In Touch</h4>
                        </div>
                        <form className="form">
                            <div className="form-field">
                                <label htmlFor="name">Enter your name*</label>
                                <input type="text" id="name"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="email">Enter your email*</label>
                                <input type="email" id="email"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="subject">Enter your subject*</label>
                                <input type="subject" id="subject"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="textarea">Enter your message*</label>
                                <textarea name="textarea" id="textarea" cols="30" rows="10"></textarea>
                            </div>
                            <div className="form-field f-button">
                                <PrimaryButton title={'Send Email'} link={'#'}/>
                            </div>
                        </form>
                    </div>
                    <div className="right-content">
                        <ContactItem icon={phone} title={'Phone'} contact1={'403-123-4567'} contact2={'403-987-6541'} />
                        <ContactItem icon={email} title={'Email'} contact1={'email1'} contact2={'email2'} />
                        <ContactItem icon={location} title={'Location'} contact1={'Calgary, Alberta'} contact2={'Canada'} />
                    </div>
                </InnerLayout>
            </ContactPageStyled>
        </MainLayout>
    )
}

const ContactPageStyled = styled.section`
    .contact-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem;

        @media screen and (max-width: 978px) {
            grid-template-columns: repeat(1, 1fr);
            
            .f-button {
                margin-bottom: 3rem;
            }
        }

        .right-content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            
            @media screen and (max-width: 540px) {
                width: 60%;
            }

            @media screen and (max-width: 520px) {
                width: 50%;
            }

        }
        
        .contact-title {
            h4 {
                color: var(--white-color);
                padding: 1rem 0;
                font-size: 1.8rem;
            }
        }
        
        .form {
            width: 100%;
            
            @media screen and (max-width: 520px) {
                width: 80%;
            }

            .form-field {
                margin-top: 2rem;
                position: relative;
                width: 100%;

                label {
                    position: absolute;
                    left: 20px;
                    top: -19px;
                    display: inline-block;
                    background-color: var(--background-dark-color);
                    padding: 0 0.5rem;
                    color: inherit;
                }

                input {
                    border: 1px solid var(--border-color);
                    outline: none;
                    background: transparent;
                    height: 50px;
                    padding: 0 15px;
                    width: 100%;
                    color: inherit;
                }

                textarea {
                    background-color: transparent;
                    border: 1px solid var(--border-color);
                    outline: none;
                    color: inherit;
                    width: 100%;
                    padding: 0.8rem 1rem;
                }
            }

        }
    }
`;

export default ContactPage;
