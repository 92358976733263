import React from 'react';
import styled from 'styled-components';
import Navigation from '../Components/Navigation';

function Sidebar({navToggle}) {
    return(
        <SidebarStyled className={`${navToggle ? 'nav-toggle' : ''}`}>
            <Navigation />
        </SidebarStyled>
    );
}

const SidebarStyled = styled.div`
    width: 16.3rem;
    position: fixed;
    height: 100vh;
    background-color: var(--sidebar-dark-color);
    overflow: hidden;
    transition: all 0.4s ease-in-out;

    @media screen and (max-width: 1200px) {
        transform: translateX(-100%);
        z-index: 20;
    }

    @media screen and (max-width: 660px) {
        width: 13rem;
    }

    @media screen and (max-width: 500px) {
        width: 12.5rem;
    }

    @media screen and (max-width: 360px) {
        width: 10rem;
    }
`;

export default Sidebar;